/* .header {
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
} */

#navbarBottom {
    overflow: hidden;
    background-color: transparent;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 34px 36px;
}

#navbarBottom a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    /* padding: 34px 36px; */
    text-decoration: none;
    font-size: 17px;

}

/* #navbarBottom a:hover {
    background-color: #ddd;
    color: black;
}

#navbarBottom a.active {
    background-color: #04AA6D;
    color: white;
} */

.navbarBottomHeading {
    font-size: 17px;
    text-decoration: none;
    margin-bottom: -2%;
}


.navbarBottomLeftSideText {
    margin-left: -5%;
    margin-top: -3%;
    /* margin-right: 5%; */
}


.navbarBottomRightSideText {
    margin-bottom: -2%;
    margin-left: -6.5%;
    margin-right: 5%;
}

.navBarLinkRight {
    right: 0;
    position: absolute;
}

.content {
    padding: 16px;
}

.stickyBottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.stickyBottom+.content {
    padding-top: 60px;
}

@media only screen and (max-width: 768px) {

    #navbarBottom a,
    .navbarBottomHeading {
        font-size: 8px;
    }

    .navbarBottomLeftSideText {
        margin-left: -13%;
        margin-top: 7%;
        /* margin-right: 5%; */
    }

    .navbarBottomRightSideText {
        margin-right: 1%;
        margin-bottom: -15%;
        margin-left: -13%;
    }




    .navBarLinkRight {
        right: 0;
        position: initial;
    }
}