:root {
    --glowing-effect: 0px 7px 20px rgba(41, 170, 238);
}

h1 {
    font-size: 34px;
    z-index: 10;
    position: relative;
}

.header {
    margin: 10px 0 0 0;
    font-weight: lighter;
    text-transform: uppercase;
    color: #eeeeee;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    position: relative;
    /* text-shadow: 2px 2px black; */
    font-size: 26px;
    font-weight: bold;
}

@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        box-shadow: var(--glowing-effect);
        transform: translatey(50px);
    }

    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        box-shadow: var(--glowing-effect);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        box-shadow: var(--glowing-effect);
        transform: translatey(0px);
    }
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.avatar {
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    border: 5px white solid;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;


    img {
        width: 100%;
        height: 100%;
        /* margin-left: -1.5rem; */
    }

}