@keyframes scrollHorizontal {
    0% {
        transform: translateY(400px);
    }

    100% {
        transform: translateY(calc(-110px * 7));
    }
}

.sliderHorizontal {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 25rem;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 560px;
}

.sliderHorizontal::before,
.sliderHorizontal::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.sliderHorizontal::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.sliderHorizontal::before {
    left: 0;
    top: 0;
}

.sliderHorizontal .slide-track-horizontal {
    animation: scrollHorizontal 10s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.sliderHorizontal .slideHorizontal {
    height: 100px;
    width: 300px;
    margin-top: 0.5rem;
}

#sliderHorizontalGithubIcon {
    margin-left: 3rem;
}

@media only screen and (max-width: 768px) {
    @keyframes scrollHorizontal {
        0% {
            transform: translateX(400px);
        }

        100% {
            transform: translateX(calc(-110px * 7));
        }
    }

    .sliderHorizontal {
        width: 100vw;
    }
}