.clientBox {
    background: black;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 400px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;

}

h3 {
    color: white;
}