:root {
  --background-color: #282c34;
  --light-blue: rgba(41, 170, 238);
}

@font-face {
  font-family: "RussoOne";
  src: url("../public/font/Russo_One/RussoOne-Regular.ttf") format('truetype');

  /* font-family: "Impact";
  src: url("../public/fonts/Impact/Impacted.ttf") format('truetype'); */
}

body {
  background-color: #242c38;
}

a:visited,
a:link {
  text-decoration: none !important;
  color: var(--light-blue);
}

.purpleBlueGradient {
  /* background-image: -webkit-linear-gradient(0deg, #00FFFF 0%, #C93BFF 81%); */
  /* background-image: -webkit-linear-gradient(0deg, #5b6ac7 0%, #d630ce 81%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  color: #2191ce; */
  text-shadow: none;
  /* color: black; */
  /* text-shadow: 2px 2px white; */
}

#background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  /* opacity: 0.3; */
}

h1 {
  text-align: center;
  text-shadow: 2px 2px black
    /* Helps a lot with standing out because of color contrast of bg */
}



.wrapper {
  position: relative;
  width: 100%;

}

/* Glowing effect */
.glowing-effect {
  padding: 0px;
  box-shadow: 5px 10px rgba(41, 170, 238);
  box-shadow: 0px 0px 0px 50px rgba(41, 170, 238);
}

.iframe {
  width: 412px;
  height: 915px;
  z-index: 10;
  position: relative;
  max-width: 100%;

}

td {
  position: relative;
}

/* .verticalLine {
  position: relative;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 15rem;
  border-right: solid 5px #fff;
  z-index: 9999;
} */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.credentialsCard {
  background: linear-gradient(49deg, #2d4de0 0, #9f71f0 30%, #fc6277 58%, #f8ef6f 95%);
  width: 22rem;
  height: 16rem;
  border-radius: 12px;
  position: relative;
  /* margin-left: 10%; */
  margin: 20px;
  padding-bottom: 2rem;
}

.credentialsCardLeftSide {
  left: 25%;

}

.credentialsContainerRightSide {
  margin-top: -38.5rem;
}

.credentialsCardRightSide {
  left: 55%;



}

.credentials-card-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 50px 40px;

  background-color: #19A8D0;
  color: #ffffff;

  border-radius: 12px;

  transition: 0.2s ease;
  animation: rotatingBgColorOne 3s forwards infinite;







}

@keyframes rotatingBgColorOne {
  0% {
    background-color: #19A8D0;
  }

  100% {
    background-color: #2F4D5E;
  }
}

@keyframes rotatingBgColorTwo {
  0% {
    background-color: #2F4D5E;
  }

  100% {
    background-color: #19A8D0;
  }
}

#diffColor {
  background-color: #19A8D0;
  animation: rotatingBgColorTwo 3s forwards infinite;
}

.credentialsCard:hover .credentials-card-container {
  border-radius: 10px;
  inset: 3px;
}

.gradientLine {
  content: '';
  position: absolute;

  width: 2rem;
  height: 1rem;
  background: linear-gradient(to bottom right, #1295dc, #1826e4);
  /* Diagonal gradient */
  z-index: -1;
  /* Ensure it's behind the content */
  transform: rotate(90deg);
  /* Rotate to create diagonal effect */
  transform-origin: top left;
  /* Ensure rotation happens from top left */
  clip-path: inset(0 0 0 0);
  /* Ensure the gradient covers only the border area */
  animation: reappear 3s forwards normal, changeGradient 1s forwards infinite;

  /* border: 10px solid orange; */


}

.vertical {
  top: 10rem;
  left: 15rem;
}

.horizontal {
  top: 12rem;
  left: 20rem;
  transform: rotate(180deg);


}

.btn-gradient-1 {
  border-left: 10px solid transparent;
  /* Set the border width and make it transparent */
  border-image: linear-gradient(to bottom, #0d31d4, #0edfdf);
  /* Define the gradient */
  border-image-slice: 1;
  height: 100px;
  margin-left: 20%;
  /* Make the gradient cover the entire border */
}

.gradient-border {
  position: relative;
  padding: 20px;
  background: transparent;
  /* Background color of the element */
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 40rem;
  width: 40%;
  height: 40%;
  background: linear-gradient(to bottom right, #1295dc, #1826e4);
  /* Diagonal gradient */
  z-index: -1;
  /* Ensure it's behind the content */
  transform: rotate(90deg);
  /* Rotate to create diagonal effect */
  transform-origin: top left;
  /* Ensure rotation happens from top left */
  clip-path: inset(0 0 0 0);
  /* Ensure the gradient covers only the border area */
  animation: reappear 3s forwards infinite, changeGradient 1s forwards infinite;

  /* border: 10px solid orange; */


}

@keyframes reappear {
  0% {
    width: 0rem;
    height: 2rem;
  }

  100% {
    width: 6rem;
    height: 2rem;

  }
}

@keyframes changeGradient {
  0% {
    background: linear-gradient(to bottom right, #1295dc, #1826e4);
  }

  100% {
    background: linear-gradient(to top left, #1295dc, #1826e4);
  }
}

.logoCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoMain {
  height: 200px;
  width: 200px;
  /* position: fixed; */
  /* animation: enlarge 3s forwards infinite; */
  animation: moving 6s ease-in-out infinite;
}

@keyframes moving {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    box-shadow: var(--glowing-effect);
    transform: translatey(50px);
  }

  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    box-shadow: var(--glowing-effect);
    transform: translatey(-20px);
  }

  100% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    box-shadow: var(--glowing-effect);
    transform: translatey(0px);
  }
}

@keyframes enlarge {
  0% {
    height: 200px;
    width: 200px;
  }

  100% {
    height: 300px;
    width: 300px;
  }
}


.gradient-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-left: 10px solid transparent;
  /* Width of the border */
  border-top: 10px solid transparent;
  /* Width of the border */
  border-image: linear-gradient(to bottom right, #ff7e5f, #feb47b) 1;
  /* Apply gradient */
  z-index: -1;
  /* Ensure it's behind the content */
  box-sizing: border-box;
  /* Ensure border box sizing is applied */


}

.clientOneDescription {
  color: black;
  top: 63rem;
  left: 45rem;
  position: absolute;

  font-size: 24px;
  /* -moz-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(30deg, 0deg);
  -webkit-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(30deg, 0deg);
  -o-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(30deg, 0deg);
  -ms-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(30deg, 0deg);
  transform: scale(1) rotate(5deg) translate(0px, 0px) skew(20deg, 0deg); */


}

.scrollHorizontalText {
  font-size: 28px;
}

.backgroundBoxCard {
  width: 60vw;
  height: 40vh;
  margin-left: 25rem;
  margin-bottom: 20rem;
}

.threeDboximage {
  position: absolute;
  height: 20vh;
  width: 45vw;
  top: 58rem;
  left: 18rem;



}

.clientOne {
  /* background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto; */
}

.threedbox {
  position: relative;
  object-fit: fixed;
}

/* .clientOneText {
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  left: 25%;
  top: 50%;
  font-size: 2rem;
} */

.pageMainHeading {

  width: 60rem;
  position: relative;
  margin: auto;
  /* left: 1.5%; */
  height: 50px;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  /* margin-left: 3.5vw; */
  margin-bottom: 20px;
  margin-top: 20px;


}

.pageMainHeadingText {
  /* font-size: 30px; */
  /* width: 100%; */
  /* position: absolute; */
  margin-top: 0.5rem;
  justify-content: center;
  /* text-align: center;
  align-items: center; */
  /* display: flex; */
}

.clientsLine {
  content: '';
  position: relative;

  /* width: 80vw; */
  height: 1rem;
  top: 4rem;
  width: 48rem;
  /* border: 10px solid blue; */

  /* Change these two values, together to adjust position of line. */
  left: 16%;
  margin-left: 51%;
  /* Change these two values, together to adjust position of line. */

  /* margin-left: 20rem; */
  background: linear-gradient(to bottom right, #2F4D5E, #1EADCC);
  /* Diagonal gradient */
  z-index: -1;
  transform: rotate(180deg);
  /* Rotate to create diagonal effect */
  transform-origin: top left;
  /* Ensure rotation happens from top left */
  clip-path: inset(0 0 0 0);
  /* Ensure the gradient covers only the border area */
  /* animation: reappear 3s forwards normal, changeGradient 1s forwards infinite; */

  /* border: 10px solid orange; */

}

.clients {
  margin-bottom: 3rem;
}

.clientImage {
  height: 100px;
  width: 100px;
  position: absolute;
  margin-bottom: 5rem;
  cursor: pointer;


}

.clientImage:hover {
  animation: clientImageMoving 1s forwards;
}

@keyframes clientImageMoving {
  0% {
    transform: translatey(0px);
  }

  100% {
    transform: translatey(-10px);
    width: 120px;
    height: 120px;
  }
}

.clientOneImage {
  margin-left: 25%;
}

.clientTwoImage {
  margin-left: 45%;
}


.clientThreeImage {
  margin-left: 65%;
  height: 70px;
  width: 70px;
}

.clientsHolder {
  /* width: 55rem;
  height: 55rem; */
  width: 37%;
  height: 48%;
  /* position: absolute; */
  margin: auto auto;
  /* border: 10px solid red; */
  left: 27%;
  right: 33%;
  /* position: absolute; */
  /* padding-top: 10rem; */
  object-fit: fixed;
  /*  object-fit: cover; - Maintain Aspect Ratio - prevents text from squashing together */
  /* display: inline-block; */
  box-shadow: 7px 7px 5px #5b5d60;


}

.clientsHeading {
  /* left: 45%;
  right: 33%; */
  font-weight: bold;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;



}

#clientOneHeading {
  position: absolute;
  top: 99%;
  left: 40%;
}

#clientsOnePara {
  position: absolute;
  top: 105%;
  left: 43%;
}

.clientsPara {
  /* top: 40%;
  left: 37%; */
  top: 0%;
  left: 37%;
  font-weight: bold;
  /* text-transform: uppercase; */
  color: #eeeeee;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-size: 1.3rem;
  /* border: 3px solid red; */
  width: 100%;
  padding-top: 2.5rem;
  position: relative;
  margin-top: -1rem;


}

.productReviewDemoVideo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25%;
  margin-bottom: 20%;
}

.productReviewVideoIframe {

  width: 60rem;
  height: 80vh;
}

#clientsImgOne {
  width: 40rem;
  height: 45rem;


}

#clientsImgTwo {
  /* padding-top: 20rem; */
}

#clientsImgThree {
  /* padding-top: 20rem; */
  /* width: 25rem;
  height: 40rem;
  left: 40%; */
}

.clientsUrl {
  margin-left: 10%;
  /* width: 15%; */
  margin-top: 3rem;
  top: 3rem;
  border: 3px solid orange;
}

.clientsUrlLink {}

.clientsUrlLinkBtn {
  background-color: rgb(48, 77, 94);
  padding: 30px;
  color: var(--light-blue);
  font-size: 24px;
  border: none;
  /* Remove default border */
  border-radius: 40px;
  /* Round the corners */
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  /* Change mouse cursor on hover */
  position: relative;
  top: 5rem;
  margin-bottom: 9rem;
  left: 21%;

}

.gradient {
  color: rgb(0, 0, 0);
  position: relative;
  z-index: 1;
  background: linear-gradient(87.75deg, rgb(28, 154, 212) -16.61%, rgb(21, 10, 231) 110.1%);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;

}

.btn {
  display: inline-flex;
  text-align: center;
  font-weight: 700;
  border-radius: 8px;
  transition: all 0.25s linear 0s;
  text-decoration: none;


}

.experiencedIn {
  text-align: center;
}

.graduationImg {
  height: 70px;
  width: 70px;
  position: relative;
  top: 0;
  left: 0;

}

#leftImg {
  position: absolute;
  top: 1rem;
  left: 45rem;
  /* border: 1px green solid; */

}

#rightImg {
  position: absolute;
  top: 1rem;
  left: 66rem;
  /* border: 1px red solid; */
}


.credentialsParentWrapper {}

.credentialsCardBg {

  width: 55rem;
  height: 70%;
  position: relative;
  /* top: 0;
  left: 0;
  top: 30%;
  left: 28%; */

  animation: border-transform-lighter 7s linear infinite;
  border-radius: 5%;


}

.shadow {
  margin-top: -2.5rem;
  margin-left: 16rem;
  height: 3rem;
  width: 25rem;
  /* border: 10px solid red; */
}

#leftShadow {
  margin-left: 0rem;
}

.credentialsBodyText {
  font-size: 1.2rem;
}

#leftText {

  position: absolute;
  /* top: 7rem; */
  left: 41rem;
  /* border: 3px solid red; */
  width: 13%;
}

#rightText {
  position: absolute;
  /* top: 7rem; */
  left: 62rem;
  width: 13%;
  /* border: 3px solid blue; */
}

.topPara {
  top: 6rem;
  /* letter-spacing: 1px; */
}

.middlePara {
  top: 12rem;
}

.bottomPara {
  top: 18rem;
  /* letter-spacing: 1px; */
}

.rounded-shadow {
  border-radius: 45px;
  /* Adjusts the roundness of the corners */
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.7);
  /* Adds the shadow effect */
  transition: all 0.3s;
  /* Optional: for a smooth hover effect */
}

.rounded-shadow:hover {

  /* Optional: Changes shadow on hover */
}

.businessCard {
  /* width: 316px;
  height: 900px; */

  width: 40rem;
  height: 50%;
  position: relative;
  top: 0;
  left: 0;
  top: 30%;
  left: 33%;

  transform: rotate(180deg);
  position: relative;


}

#contactBgImage {
  transform: rotate(180deg);

}

.contactIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  margin-right: 40px;
  bottom: 2%;



}

.logo {
  /* border: 20px solid orange; */
  position: absolute;
  width: 17rem;
  height: 13rem;
  margin-top: 3rem;
  object-fit: contain;
  margin-left: 20rem;

  margin-top: 5vh;
  margin-left: 18%;
}

#githubIcon {
  /* margin-top: 8rem; */
  margin-left: -15%;
}

#linkedinIcon {
  /* margin-top: 14rem; */
  margin-left: 0%;
}

#mailIcon {
  /* margin-top: 2rem; */
}

@keyframes border-transform-lighter {
  0% {
    border-radius: 10%
  }

  22% {
    border-radius: 10%
  }

  44% {
    border-radius: 20%
  }

  66% {
    border-radius: 25%
  }

  88% {
    border-radius: 20%
  }

  100% {
    border-radius: 10%
  }
}

@keyframes clientsScroll {
  0% {
    transform: translateX(200px)
  }

  100% {
    transform: translateX(-110px)
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1800px) {
  .credentialsBodyText {
    font-size: 1.1rem;
    margin-left: -5%;

  }

  #leftText {
    left: 41rem;
    width: 17%;
  }

  #rightText {
    left: 62rem;
    width: 17%;
  }

  .contactIcon {
    position: absolute;
    width: 60px;
    height: 60px;
    margin-left: -0vw;

  }

  .logo {
    width: 15rem;
    height: 12rem;
    margin-top: 5vh;
    margin-left: 14%;
  }


}

@media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .credentialsBodyText {
    font-size: 1rem;
    margin-left: -12%;
  }

  .graduationImg {
    margin-left: -12%;

  }

  #leftText {
    left: 41rem;
    width: 16%;
  }

  #rightText {
    left: 62rem;
    width: 16%;
  }

  .contactIcon {
    position: absolute;
    width: 60px;
    height: 60px;
    margin-left: -7vw;

  }

  .logo {
    width: 15rem;
    height: 12rem;
    margin-top: 5vh;
    margin-left: 11%;
  }
}

@media only screen and (min-width: 740px) and (max-width: 1100px) {
  .credentialsBodyText {
    font-size: 0.9rem;
    margin-left: -26%;

  }

  .graduationImg {
    margin-left: -30%;

  }

  #leftText {
    left: 37rem;
    width: 22%;
  }

  #rightText {
    left: 58rem;
    width: 22%;
  }

  .contactIcon {
    position: absolute;
    width: 60px;
    height: 60px;
    margin-left: -20vw;

  }

  .logo {
    width: 15rem;
    height: 12rem;
    margin-top: 6vh;
    margin-left: 4%;
  }
}

@media only screen and (max-width: 768px) {

  /* * {
    border: 10px solid blue;
  } */

  #background-video {
    object-fit: fill;
  }

  .iframe {
    width: 0rem;
    height: 30rem;
    right: 0;
    width: 100%;
  }

  .pageMainHeading {

    width: 100%;
  }

  .credentialsCard {
    width: 18rem;
    height: 12rem;
    padding-bottom: 6rem;
    left: 7%;



  }

  .credentialsCardLeftSide {}

  .credentialsContainerRightSide {
    margin-top: 0;
  }

  .credentialsCardBg {
    width: 98%;
    top: 3%;
    left: 1%;
    height: 25rem;
  }

  #leftImg {
    top: 1rem;
    left: 15vw;
    /* text overtop image use vw and not rem for more mobile consistency across
    multiple mobile devices */
    /* border: 1px green solid; */

  }

  #rightImg {
    top: 1rem;
    left: 65vw;
    /* border: 1px red solid; */
  }

  #leftText {
    left: 1.5rem;
    width: 40%;
  }

  #rightText {
    /* top: 7rem; */
    left: 55vw;
    /* text overtop image use vw and not rem for more mobile consistency across
                multiple mobile devices */
    width: 40%;
    /* border: 3px solid blue; */
  }

  .topPara {
    top: 6rem;
  }

  .bottomPara {
    top: 14rem;
  }

  .clientsLine {
    top: 4rem;
    /* Change these two values, together to adjust position of line. */
    left: 20%;
    margin-left: 60%;
    width: 15rem;

  }

  .clientImage {
    /* height: 100px;
    width: 100px; */
  }

  .clientOneImage {
    margin-left: 5%;
  }

  .clientTwoImage {
    margin-left: 35%;
  }

  .clientsHolder {
    /* width: 55rem;
    height: 55rem; */
    /* width: 400px;
    height: 900px; */
    width: 90%;
    /* percentage worked better than pixels and also vw, vh. For consistency
                    across multiple mobile devices. */
    height: 80%;
    /* margin-left: 10%; */
    /* padding-left: 10px;
    padding-right: 10px; */
    /* margin: 15px 15px 15px 15px; */
    /* width: 90vw;
    height: 90vh; */
    /* position: relative; */
    /* margin: auto auto; */
    margin-left: 5%;
    /* border: 10px solid red; */
    left: unset;
    right: unset;
    /* position: absolute; */
    /* padding-top: 10rem; */
    /* object-fit: cover; */
    /*  object-fit: cover; - Maintain Aspect Ratio - prevents text from squashing together */
    /* display: inline-block; */


  }

  #clientsHeading {
    left: unset;
    right: unset;
    text-align: center;
  }

  .clientsPara {
    top: unset;
    margin-left: -8.5rem;
    font-weight: lighter;


  }

  .clientsUrlLinkBtn {
    left: 30vw;
  }

  /* CONTACT */


  #contactBgImage {
    transform: rotate(180deg);
    /* border: 10px solid red; */
    /* position: relative; */
    height: 20rem;
  }

  .businessCard {
    /* transform: rotate(180deg); */

  }

  .contactIcon {
    /* border: 10px solid blue; */
    /* margin-left: -20rem; */
    /* margin-left: -80vw; */
    margin-bottom: 3px;
  }

  .logo {
    width: 40%;
    height: 50%;
    margin-top: -5%;
    margin-left: -45%;
  }


  #githubIcon {
    /* margin-top: 15rem; */
    margin-left: -25%;
  }

  #linkedinIcon {
    /* margin-top: 9rem; */
    margin-left: 5%;
  }

  #mailIcon {
    /* margin-top: 3rem; */
  }

  .productReviewDemoVideo {
    margin-left: 5%;
    margin-bottom: 15%;
  }

  .productReviewVideoIframe {

    width: 90vw;
    height: 80vh;
  }

}