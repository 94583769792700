@keyframes scroll {
    0% {
        transform: translateX(700px);
    }

    100% {
        transform: translateX(calc(-110px * 7));
    }
}

.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;

}

.slider::before,
.slider::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    animation: scroll 10s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slider .slide {
    height: 100px;
    width: 150px;
    margin-top: 0.5rem;
}

#sliderGithubIcon {
    margin-left: 3rem;
}

@media only screen and (max-width: 768px) {
    @keyframes scroll {
        0% {
            transform: translateX(400px);
        }

        100% {
            transform: translateX(calc(-110px * 7));
        }
    }

    .slider {
        width: 100vw;
    }
}