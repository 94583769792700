body,
#slider,
.wrap,
.slide-content {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: 80vh;
    overflow-x: hidden;
}

#slider {
    width: 80%;
    margin-left: 10%;

}

.wrap {
    position: relative;
    margin-bottom: 10rem;
}

.slide {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

}


.clientOneSlide1 {
    background-image: url("../public/img/clients/monthomes-1.png");
}

.clientOneSlide2 {
    background-image: url("../public/img/clients/monthomes-2.png");
}

.clientOneSlide3 {
    background-image: url("../public/img/clients/monthomes-3.png");
}

.clientOneSlide4 {
    background-image: url("../public/img/clients/monthomes-4.png");
}

.clientOneSlide5 {
    background-image: url("../public/img/clients/monthomes-5.png");
}

.clientTwoSlide1 {
    background-image: url("../public/img/clients/swearFreeVideos-screenshot.png");
}

.clientTwoSlide2 {
    background-image: url("../public/img/clients/swearFreeVideos-screenshot-v2.png");
}

.show {
    display: block
}

.hide {
    display: none;
}

/* 
.bathSlide1 {
    background-image: url("../public/img/bath/bath18.jpg");
}

.bathSlide2 {
    background-image: url("../public/img/bath/bath9.jpg");
}

.bathSlide3 {
    background-image: url("../public/img/bath/bath2.jpg");
}

.basementSlide1 {
    background-image: url("../public/img/basement/basement1.jpg");
}

.basementSlide2 {
    background-image: url("../public/img/basement/basement2.jpg");
}

.basementSlide3 {
    background-image: url("../public/img/basement/basement3.jpg");
}

.poolSlide1 {
    background-image: url("../public/img/pools/pool2.jpg");
}

.poolSlide2 {
    background-image: url("../public/img/pools/pool3.jpg");
}

.poolSlide3 {
    background-image: url("../public/img/pools/pool4.jpg");
}

.patioSlide1 {
    background-image: url("../public/img/patio/patio1.jpg");
}

.patioSlide2 {
    background-image: url("../public/img/patio/patio2.jpg");
} */

/* .patioSlide3 {
    background-image: url("../public/img/patio/patio4.jpg");
} */

.slide-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.slide-content span {
    font-size: 5rem;
    color: #fff;
}

.arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    width: 0;
    height: 0;
    border-style: solid;
}

.arrowLeft {
    border-width: 30px 40px 30px 0;
    border-color: transparent #fff transparent transparent;
    left: 0;
    /* margin-left: 30px; */
    margin-left: 15%;
}

.arrowRight {
    content: "PREV";
    border-width: 30px 0 30px 40px;
    border-color: transparent transparent transparent #fff;
    right: 0;
    /* margin-right: 30px; */
    margin-right: 15%;
}

.arrowRight::before {
    content: "NEXT";
    font-size: 26px;
    font-weight: bold;
    position: absolute;
    bottom: -4rem;
    margin-left: -3.5rem;
}


.arrowLeft::before {
    content: "PREV";
    font-weight: bold;
    font-size: 26px;
    position: absolute;
    bottom: -4rem;
    margin-right: -3.5rem;
}

@media only screen and (max-width: 768px) {
    #slider {
        width: 100%;
        margin-left: 0%;

    }

    .wrap {
        margin-top: -3rem;
        margin-bottom: 0rem;
    }

    .arrowLeft {
        margin-left: 30px;
    }

    .arrowRight {
        margin-right: 30px;
    }
}