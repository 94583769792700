/* .header {
    background-color: #f1f1f1;
    padding: 30px;
    text-align: center;
} */

#navbarTop {
    overflow: hidden;
    background-color: transparent;
    z-index: 11;
}

#navbarTop a,
.navbarTopPara {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

#navbarTop a:hover,
.navbarTopPara:hover {
    background-color: #ddd;
    color: black;
}

#navbarTop a.active {
    background-color: #04AA6D;
    color: white;
    font-weight: bold;
}

.navBarLinkRight {
    right: 0;
    position: absolute;
}

.content {
    padding: 16px;
}

.stickyTop {
    position: fixed;
    top: 0;
    width: 100%;
}

.stickyTop+.content {
    padding-top: 60px;
}

@media only screen and (max-width: 768px) {

    #navbarTop a,
    .navbarTopPara {
        font-size: 8px;
    }

    .navBarLinkRight {
        right: 0;
        position: initial;
    }
}